<template>
  <Layout>
    <PageHeader :title="title" :items="items" />
    <CreateEditTimeModal
      v-if="showCreateEditTimeModal"
      :timeId="timeIdSelected"
      @closeModal="closeCreateEditTimeModal"
    />
    <PreSumula
      v-if="showPreSumulaModal"
      :timeId="timeIdSelected"
      @closeModal="closePreSumulaModal"
    />
    <LoadingData v-if="loadData" />
    <div v-else class="card">
      <div
        class="card-header d-flex justify-content-between align-items-center bg-transparent border-bottom"
      >
        <h5 class="card-title mb-0">Times</h5>
        <div v-if="getModuleUserByCode('0.07.002')">
          <button
            type="button"
            class="btn btn-success"
            title="Relatório"
            @click="generatePDF()"
          >
            Gerar PDF
          </button>
          <!-- <button
            type="button"
            class="btn btn-primary"
            title="Novo Time"
            @click="createEditTime()"
          >
            Novo Time
          </button> -->
        </div>
      </div>
      <div class="card-body">
        <ListTimes
          :times="times"
          @editTime="createEditTime"
          @viewPreSumula="viewPreSumula"
        />
      </div>
      <div class="card-body" id="pdf-content" v-if="showPdfContent">
        <ListTimesRelatorio :times="times" />
      </div>
    </div>
  </Layout>
</template>

<script>
import { mapGetters } from 'vuex'
import Layout from '@/views/layouts/Layout.vue'
import PageHeader from '@/components/commons/PageHeader.vue'
import LoadingData from '@/components/commons/LoadingData.vue'
import html2pdf from 'html2pdf.js'
import CreateEditTimeModal from '@/components/administration/times/CreateEditTimeModal.vue'
import ListTimes from '../../../components/administration/times/ListTimes.vue'
import ListTimesRelatorio from '../../../components/administration/times/ListTimesRelatorio.vue'
import PreSumula from '../../../components/administration/times/PreSumula.vue'

export default {
  name: 'Times',

  components: {
    Layout,
    PageHeader,
    CreateEditTimeModal,
    LoadingData,
    ListTimes,
    ListTimesRelatorio,
    PreSumula,
  },

  data() {
    return {
      title: 'Times',
      items: [
        {
          text: 'Administração',
        },
        {
          text: 'Times',
          active: true,
        },
      ],
      loadData: true,
      showCreateEditTimeModal: false,
      showPreSumulaModal: false,
      times: null,
      timeIdSelected: null,
      showPdfContent: false,
      showPreSumula: false,
    }
  },

  computed: {
    ...mapGetters(['getModuleUserByCode']),
  },

  methods: {
    generatePDF() {
      this.showPdfContent = true

      this.$nextTick(() => {
        const element = document.getElementById('pdf-content')
        html2pdf()
          .from(element)
          .toPdf()
          .get('pdf')
          .then(function generatePdfBlob(pdf) {
            const blob = pdf.output('blob')
            const url = URL.createObjectURL(blob)
            window.open(url)
          })
          .finally(() => {
            this.showPdfContent = false
          })
      })
    },

    async getTimes() {
      try {
        this.times = await this.$store.dispatch('getTimes')
        this.loadData = false
      } catch (error) {
        if (error && error.message) {
          this.$root.$bvToast.toast(error.message, {
            title: 'Atenção!',
            variant: 'warning',
            solid: true,
          })
        } else {
          this.$root.$bvToast.toast(
            'Não foi possível se conectar ao servidor.',
            {
              title: 'Erro!',
              variant: 'danger',
              solid: true,
            },
          )
        }
      }
    },

    async getTimesByEscola() {
      try {
        this.times = await this.$store.dispatch('getTimesByEscola')
        this.loadData = false
      } catch (error) {
        if (error && error.message) {
          this.$root.$bvToast.toast(error.message, {
            title: 'Atenção!',
            variant: 'warning',
            solid: true,
          })
        } else {
          this.$root.$bvToast.toast(
            'Não foi possível se conectar ao servidor.',
            {
              title: 'Erro!',
              variant: 'danger',
              solid: true,
            },
          )
        }
      }
    },

    createEditTime(timeId) {
      this.timeIdSelected = timeId || null
      this.showCreateEditTimeModal = true
    },

    viewPreSumula(timeId) {
      this.timeIdSelected = timeId || null
      this.showPreSumulaModal = true
    },

    closeCreateEditTimeModal(loadListTimes) {
      if (loadListTimes) {
        if (this.getModuleUserByCode('1')) {
          this.getTimes()
        } else if (this.getModuleUserByCode('2')) {
          this.getTimesByEscola()
        }
      }
      this.showCreateEditTimeModal = false
    },

    closePreSumulaModal(loadListTimes) {
      if (loadListTimes) {
        if (this.getModuleUserByCode('1')) {
          this.getTimes()
        } else if (this.getModuleUserByCode('2')) {
          this.getTimesByEscola()
        }
      }
      this.showPreSumulaModal = false
    },
  },

  mounted() {
    if (this.getModuleUserByCode('1')) {
      this.getTimes()
    } else if (this.getModuleUserByCode('2')) {
      this.getTimesByEscola()
    }
  },
}
</script>
