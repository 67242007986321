<template>
  <Layout>
    <PageHeader :title="title" :items="items" />
    <CreateEditTecnicoModal
      v-if="showCreateEditTecnicoModal"
      :tecnicoId="tecnicoIdSelected"
      @closeModal="closeCreateEditTecnicoModal"
    />
    <LoadingData v-if="loadData" />
    <div v-else class="card">
      <div
        class="card-header d-flex justify-content-between align-items-center bg-transparent border-bottom"
      >
        <h5 class="card-title mb-0">Técnicos Responsáveis</h5>
        <div v-if="getModuleUserByCode('0.06.002')">
          <button
            type="button"
            class="btn btn-success"
            title="Relatório"
            @click="generatePDF()"
          >
            Gerar PDF
          </button>
          <!-- <button
            type="button"
            class="btn btn-primary"
            title="Novo Usuário"
            @click="createEditTecnico()"
          >
            Novo Técnico
          </button> -->
        </div>
      </div>
      <div v-if="getModuleUserByCode('0.06.002')" class="card-body">
        <ListTecnicos :tecnicos="tecnicos" @editTecnico="createEditTecnico" />
        <div id="pdf-content" v-if="showPdfContent">
          <ListTecnicosRelatorio :tecnicos="tecnicos" />
        </div>
      </div>
    </div>
  </Layout>
</template>

<script>
import { mapGetters } from 'vuex'
import Layout from '@/views/layouts/Layout.vue'
import PageHeader from '@/components/commons/PageHeader.vue'
import LoadingData from '@/components/commons/LoadingData.vue'
import CreateEditTecnicoModal from '@/components/administration/tecnicos/CreateEditTecnicoModal.vue'
import html2pdf from 'html2pdf.js'
import ListTecnicos from '../../../components/administration/tecnicos/ListTecnicos.vue'
import ListTecnicosRelatorio from '../../../components/administration/tecnicos/ListTecnicosRelatorio.vue'

export default {
  name: 'Técnicos',

  components: {
    Layout,
    PageHeader,
    CreateEditTecnicoModal,
    LoadingData,
    ListTecnicos,
    ListTecnicosRelatorio,
  },

  data() {
    return {
      title: 'Técnicos',
      items: [
        {
          text: 'Administração',
        },
        {
          text: 'Técnicos',
          active: true,
        },
      ],
      loadData: true,
      showCreateEditTecnicoModal: false,
      tecnicos: null,
      tecnicoIdSelected: null,
      showPdfContent: false,
    }
  },

  computed: {
    ...mapGetters(['getModuleUserByCode']),
  },

  methods: {
    generatePDF() {
      this.showPdfContent = true

      this.$nextTick(() => {
        const element = document.getElementById('pdf-content')
        html2pdf()
          .from(element)
          .toPdf()
          .get('pdf')
          .then(function generatePdfBlob(pdf) {
            const blob = pdf.output('blob')
            const url = URL.createObjectURL(blob)
            window.open(url)
          })
          .finally(() => {
            this.showPdfContent = false
          })
      })
    },
    async getTecnicos() {
      try {
        this.tecnicos = await this.$store.dispatch('getTecnicos')
        this.loadData = false
      } catch (error) {
        if (error && error.message) {
          this.$root.$bvToast.toast(error.message, {
            title: 'Atenção!',
            variant: 'warning',
            solid: true,
          })
        } else {
          this.$root.$bvToast.toast(
            'Não foi possível se conectar ao servidor.',
            {
              title: 'Erro!',
              variant: 'danger',
              solid: true,
            },
          )
        }
      }
    },

    async getTecnicosByEscola() {
      try {
        this.tecnicos = await this.$store.dispatch('getTecnicosByEscola')
        this.loadData = false
      } catch (error) {
        if (error && error.message) {
          this.$root.$bvToast.toast(error.message, {
            title: 'Atenção!',
            variant: 'warning',
            solid: true,
          })
        } else {
          this.$root.$bvToast.toast(
            'Não foi possível se conectar ao servidor.',
            {
              title: 'Erro!',
              variant: 'danger',
              solid: true,
            },
          )
        }
      }
    },

    createEditTecnico(tecnicoId) {
      this.tecnicoIdSelected = tecnicoId || null
      this.showCreateEditTecnicoModal = true
    },

    closeCreateEditTecnicoModal(loadListTecnicos) {
      if (loadListTecnicos) {
        if (this.getModuleUserByCode('1')) {
          this.getTecnicos()
        } else if (this.getModuleUserByCode('2')) {
          this.getTecnicosByEscola()
        }
      }
      this.showCreateEditTecnicoModal = false
    },
  },

  mounted() {
    if (this.getModuleUserByCode('1')) {
      this.getTecnicos()
    } else if (this.getModuleUserByCode('2')) {
      this.getTecnicosByEscola()
    }
  },
}
</script>
