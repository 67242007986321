<template>
  <div>
    <loading-data v-if="loadData" />
    <div v-else class="card">
      <div class="card-header bg-transparent border-bottom">
        <h5 class="card-title mb-0">Dados da Escola</h5>
      </div>
      <div class="card-body">
        <div class="row">
          <div class="col-md-4 col-sm-6">
            <p><strong>Nome:</strong> {{ escola.descricao }}</p>
          </div>
          <div class="col-md-4 col-sm-6">
            <p><strong>CNPJ:</strong> {{ escola.cnpj }}</p>
          </div>
          <div class="col-md-4 col-sm-6">
            <p><strong>Telefone:</strong> {{ escola.phone }}</p>
          </div>
          <div class="col-md-4 col-sm-6">
            <p><strong>E-mail:</strong> {{ escola.email }}</p>
          </div>
          <div class="col-md-4 col-sm-6">
            <p>
              <strong>Cidade/Estado:</strong> {{ escola.city }}/{{ escola.uf }}
            </p>
          </div>
          <div class="col-md-4 col-sm-6">
            <p>
              <strong>Responsável:</strong>
              {{ escola.responsavel }}
            </p>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import LoadingData from '@/components/commons/LoadingData.vue'

export default {
  name: 'EscolaDados',

  components: {
    LoadingData,
  },

  props: {
    escolaId: {
      type: String,
      required: true,
    },
  },

  data() {
    return {
      loadData: true,
      escola: {},
    }
  },

  methods: {
    async getEscola() {
      try {
        this.escola = await this.$store.dispatch('getEscola', this.escolaId)
        this.loadData = false
      } catch (error) {
        if (error && error.message) {
          this.$root.$bvToast.toast(error.message, {
            title: 'Atenção!',
            variant: 'warning',
            solid: true,
          })
        } else {
          this.$root.$bvToast.toast(
            'Não foi possível se conectar ao servidor.',
            {
              title: 'Erro!',
              variant: 'danger',
              solid: true,
            },
          )
        }
      }
    },
  },

  mounted() {
    this.getEscola()
  },
}
</script>
