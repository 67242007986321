<template>
  <b-modal
    id="modalCreateEditTime"
    size="xl"
    :title="textModal"
    title-class="font-18"
    hide-footer
    no-close-on-backdrop
    @hidden="$emit('closeModal', false)"
  >
    <form @submit.prevent="save">
      <div class="form-row">
        <div class="form-group col-sm-4">
          <label>Descrição: <span class="text-danger">*</span></label>
          <input
            type="text"
            class="form-control"
            :class="{ 'is-invalid': $v.time.descricao.$error }"
            placeholder="Nome do time"
            v-model="$v.time.descricao.$model"
          />
          <span v-if="!$v.time.descricao.required" class="invalid-feedback">
            O campo é obrigatório
          </span>
        </div>
        <div class="form-group col-sm-4">
          <label>Modalidade: <span class="text-danger">*</span></label>
          <select
            class="form-control"
            :class="{ 'is-invalid': $v.time.descricao.$error }"
            v-model="$v.time.modalidade_id.$model"
          >
            <option value="">Selecione uma modalidade</option>
            <option
              v-for="modalidade in modalidades"
              :key="modalidade.id"
              :value="modalidade.id"
              :title="`${modalidade.descricao}`"
            >
              {{ modalidade.descricao }}
            </option>
          </select>
          <span v-if="!$v.time.modalidade_id.required" class="invalid-feedback">
            O campo é obrigatório
          </span>
        </div>
        <div class="form-group col-sm-4">
          <label>Categoria: <span class="text-danger">*</span></label>
          <select
            class="form-control"
            :class="{ 'is-invalid': $v.time.categoria_id.$error }"
            v-model="$v.time.categoria_id.$model"
          >
            <option value="">Selecione uma categoria</option>
            <option
              v-for="categoria in categorias"
              :key="categoria.id"
              :value="categoria.id"
              :title="`${categoria.descricao}`"
            >
              {{ categoria.descricao }}
            </option>
          </select>
          <span v-if="!$v.time.categoria_id.required" class="invalid-feedback">
            O campo é obrigatório
          </span>
        </div>
      </div>
      <hr />
      <div class="col-lg-12">
        <b-tabs
          justified
          nav-class="nav-tabs-custom"
          content-class="p-3 text-muted"
        >
          <b-tab active>
            <template v-slot:title>
              <span class="d-inline-block d-sm-none">
                <i class="fas fa-home"></i>
              </span>
              <span class="d-none d-sm-inline-block">Técnico</span>
            </template>
            <label>Adicionar técnico: <span class="text-danger">*</span></label>
            <div class="form-row">
              <div class="form-group col-sm-8">
                <select class="form-control" v-model="tecnicoSelecionado">
                  <option value="">Selecione um técnico</option>
                  <option
                    v-for="tecnico in tecnicos"
                    :key="tecnico.id"
                    :value="tecnico.id"
                    :title="`${tecnico.nome} - ${tecnico.data_nascimento}`"
                  >
                    {{ tecnico.nome }} -
                    {{ dateTimeSqlToDateBR(tecnico.data_nascimento) }}
                  </option>
                </select>
              </div>
              <div class="form-group col-sm-4 d-none d-sm-inline-block">
                <div>
                  <button
                    type="button"
                    @click="addTecnico()"
                    class="btn btn-primary"
                    title="Adicionar técnico"
                  >
                    <i class="bx ri-add-line"></i>
                  </button>
                </div>
              </div>
            </div>
            <hr />
            <div
              v-for="(tecnico, index) in tecnicosTime"
              :key="index"
              class="card task-box"
            >
              <div class="card-body">
                {{ index + 1 }}
                <div class="form-row">
                  <div class="form-group col-sm-6">
                    <label>Nome: <span class="text-danger">*</span></label>
                    <input
                      type="text"
                      class="form-control"
                      placeholder="Nome do técnico"
                      v-model="tecnicosTime[index].nome"
                      readonly
                    />
                  </div>
                  <div class="form-group col-sm-3">
                    <label
                      >Data de nascimento:
                      <span class="text-danger">*</span></label
                    >
                    <input
                      type="date"
                      class="form-control"
                      placeholder="Data de nascimento"
                      v-model="tecnicosTime[index].data_nascimento"
                      readonly
                    />
                  </div>
                  <div class="form-group col-sm-1">
                    <br />
                    <button
                      type="button"
                      @click="deleteTecnico(index)"
                      class="btn btn-lg btn-outline-danger"
                      title="Excluir Técnico"
                    >
                      <i class="bx ri-delete-bin-line"></i>
                    </button>
                  </div>
                </div>
              </div>
            </div>
          </b-tab>
          <b-tab>
            <template v-slot:title>
              <span class="d-inline-block d-sm-none">
                <i class="fas fa-home"></i>
              </span>
              <span class="d-none d-sm-inline-block">Atletas</span>
            </template>
            <label>Adicionar atletas: <span class="text-danger">*</span></label>
            <div class="form-row">
              <div class="form-group col-sm-8">
                <select class="form-control" v-model="atletaSelecionado">
                  <option value="">Selecione um atleta</option>
                  <option
                    v-for="atleta in atletas"
                    :key="atleta.id"
                    :value="atleta.id"
                    :title="`${atleta.nome} - ${atleta.data_nascimento}`"
                  >
                    {{ atleta.nome }} -
                    {{ dateTimeSqlToDateBR(atleta.data_nascimento) }}
                  </option>
                </select>
              </div>
              <div class="form-group col-sm-4 d-none d-sm-inline-block">
                <div>
                  <button
                    type="button"
                    @click="addAtleta()"
                    class="btn btn-primary"
                    title="Adicionar atleta ao time"
                  >
                    <i class="bx ri-add-line"></i>
                  </button>
                </div>
              </div>
            </div>
            <hr />
            <div
              v-for="(atleta, index) in atletasTime"
              :key="index"
              class="card task-box"
            >
              <div class="card-body">
                {{ index + 1 }}
                <div class="form-row">
                  <div class="form-group col-sm-6">
                    <label>Nome: <span class="text-danger">*</span></label>
                    <input
                      type="text"
                      class="form-control"
                      placeholder="Nome do atleta"
                      v-model="atletasTime[index].nome"
                      readonly
                    />
                  </div>
                  <div class="form-group col-sm-3">
                    <label
                      >Data de nascimento:
                      <span class="text-danger">*</span></label
                    >
                    <input
                      type="date"
                      class="form-control"
                      placeholder="Data de nascimento"
                      v-model="atletasTime[index].data_nascimento"
                      readonly
                    />
                  </div>
                  <div class="form-group col-sm-1">
                    <br />
                    <button
                      type="button"
                      @click="deleteAtleta(index)"
                      class="btn btn-lg btn-outline-danger"
                      title="Excluir atleta do time"
                    >
                      <i class="bx ri-delete-bin-line"></i>
                    </button>
                  </div>
                </div>
              </div>
            </div>
          </b-tab>
        </b-tabs>
      </div>

      <div v-if="timeId" class="form-row">
        <div class="form-group col-sm-6">
          <p>Status: <span class="text-danger">*</span></p>
          <div class="form-check form-check-inline">
            <input
              type="radio"
              class="form-check-input"
              id="statusInativo"
              value="0"
              v-model="time.status"
            />
            <label class="form-check-label" for="statusInativo">
              Inativo
            </label>
          </div>
          <div class="form-check form-check-inline">
            <input
              type="radio"
              class="form-check-input"
              id="statusAtivo"
              value="1"
              v-model="time.status"
            />
            <label class="form-check-label" for="statusAtivo"> Ativo </label>
          </div>
        </div>
      </div>
      <hr />
      <div class="modal-footer-custom">
        <button
          type="button"
          class="btn btn-secondary"
          title="Cancelar"
          @click="$emit('closeModal', false)"
        >
          Cancelar
        </button>
        <button
          type="submit"
          class="btn btn-primary"
          :title="textModal"
          :disabled="$v.$invalid || disableButton"
        >
          <i
            v-if="disableButton"
            class="spinner-grow spinner-grow-custom text-wither"
          />
          <span v-else>{{ textModal }}</span>
        </button>
      </div>
    </form>
  </b-modal>
</template>

<script>
import { required, minLength } from 'vuelidate/lib/validators'
import { dateTimeSqlToDateBR } from '@/utils/date'

export default {
  name: 'CreateEditTimeModal',
  props: {
    timeId: {
      type: String,
      default: null,
    },
  },

  data() {
    return {
      time: {
        descricao: '',
        modalidade_id: '',
        categoria_id: '',
      },
      tecnicoSelecionado: '',
      atletaSelecionado: '',
      atletas: null,
      tecnicos: null,
      modalidades: null,
      categorias: null,
      atletasTime: [],
      tecnicosTime: [],
      disableButton: false,
      dateTimeSqlToDateBR,
      loadDataEditing: false,
    }
  },

  computed: {
    textModal() {
      return this.timeId ? 'Atualizar Time' : 'Cadastrar Time'
    },
  },

  watch: {
    'time.modalidade_id': {
      handler(newValue, oldValue) {
        if (!this.loadDataEditing) {
          this.time.categoria_id = ''
        }
        if (newValue !== '') {
          this.getCategorias()
        }
      },
    },
  },

  methods: {
    addAtleta() {
      const atletaSelected = this.atletas.find(
        (atleta) => atleta.id === this.atletaSelecionado,
      )
      const aux = this.atletasTime.find(
        (atleta) => atleta.id === this.atletaSelecionado,
      )
      if (!aux) {
        this.atletasTime.push({
          id: atletaSelected.id,
          nome: atletaSelected.nome,
          data_nascimento: atletaSelected.data_nascimento,
        })
        this.atletaSelecionado = ''
      } else {
        this.$root.$bvToast.toast('Atleta já adicionado', {
          title: 'Atenção!',
          variant: 'warning',
          solid: true,
        })
      }
    },
    deleteAtleta(index) {
      this.atletasTime.splice(index, 1)
    },
    addTecnico() {
      const tecnicoSelected = this.tecnicos.find(
        (tecnico) => tecnico.id === this.tecnicoSelecionado,
      )
      const aux = this.tecnicosTime.find(
        (tecnico) => tecnico.id === this.tecnicoSelecionado,
      )
      if (!aux) {
        this.tecnicosTime.push({
          id: tecnicoSelected.id,
          nome: tecnicoSelected.nome,
          data_nascimento: tecnicoSelected.data_nascimento,
        })
        this.tecnicoSelecionado = ''
      } else {
        this.$root.$bvToast.toast('Técnico já adicionado', {
          title: 'Atenção!',
          variant: 'warning',
          solid: true,
        })
      }
    },
    deleteTecnico(index) {
      this.tecnicosTime.splice(index, 1)
    },
    async getTime() {
      try {
        this.loadDataEditing = true
        this.time = await this.$store.dispatch('getTime', this.timeId)
        this.tecnicosTime = await this.$store.dispatch(
          'getTecnicosByTime',
          this.timeId,
        )
        this.atletasTime = await this.$store.dispatch(
          'getAtletasByTime',
          this.timeId,
        )
        this.$bvModal.show('modalCreateEditTime')
      } catch (error) {
        if (error && error.message) {
          this.$root.$bvToast.toast(error.message, {
            title: 'Atenção!',
            variant: 'warning',
            solid: true,
          })
        } else {
          this.$root.$bvToast.toast(
            'Não foi possível se conectar ao servidor.',
            {
              title: 'Erro!',
              variant: 'danger',
              solid: true,
            },
          )
        }
        this.$emit('closeModal', false)
      }
    },

    async getModalidades() {
      try {
        this.modalidades = await this.$store.dispatch('getModalidades')
      } catch (error) {
        if (error && error.message) {
          this.$root.$bvToast.toast(error.message, {
            title: 'Atenção!',
            variant: 'warning',
            solid: true,
          })
        } else {
          this.$root.$bvToast.toast(
            'Não foi possível se conectar ao servidor.',
            {
              title: 'Erro!',
              variant: 'danger',
              solid: true,
            },
          )
        }
      }
    },

    async getCategorias() {
      try {
        this.categorias = await this.$store.dispatch(
          'getCategoriasHasModalidade',
          this.time.modalidade_id,
        )
        this.loadDataEditing = false
        this.loadData = false
      } catch (error) {
        if (error && error.message) {
          this.$root.$bvToast.toast(error.message, {
            title: 'Atenção!',
            variant: 'warning',
            solid: true,
          })
        } else {
          this.$root.$bvToast.toast(
            'Não foi possível se conectar ao servidor.',
            {
              title: 'Erro!',
              variant: 'danger',
              solid: true,
            },
          )
        }
      }
    },

    async getAtletasByEscola() {
      try {
        this.atletas = await this.$store.dispatch('getAtletasByEscola')
      } catch (error) {
        if (error && error.message) {
          this.$root.$bvToast.toast(error.message, {
            title: 'Atenção!',
            variant: 'warning',
            solid: true,
          })
        } else {
          this.$root.$bvToast.toast(
            'Não foi possível se conectar ao servidor.',
            {
              title: 'Erro!',
              variant: 'danger',
              solid: true,
            },
          )
        }
      }
    },

    async getTecnicosByEscola() {
      try {
        this.tecnicos = await this.$store.dispatch('getTecnicosByEscola')
      } catch (error) {
        if (error && error.message) {
          this.$root.$bvToast.toast(error.message, {
            title: 'Atenção!',
            variant: 'warning',
            solid: true,
          })
        } else {
          this.$root.$bvToast.toast(
            'Não foi possível se conectar ao servidor.',
            {
              title: 'Erro!',
              variant: 'danger',
              solid: true,
            },
          )
        }
      }
    },

    async save() {
      if (!this.$v.$invalid) {
        this.disableButton = true
        try {
          let response = null
          if (this.timeId) {
            response = await this.$store.dispatch('updateTime', {
              id: this.timeId,
              time: this.time,
              atletasTime: this.atletasTime,
              tecnicosTime: this.tecnicosTime,
            })
          } else {
            response = await this.$store.dispatch('saveTime', {
              time: this.time,
              atletasTime: this.atletasTime,
              tecnicosTime: this.tecnicosTime,
            })
          }
          this.disableButton = false
          this.$root.$bvToast.toast(response.message, {
            title: 'Sucesso!',
            variant: 'success',
            solid: true,
          })
          this.$emit('closeModal', true)
        } catch (error) {
          this.disableButton = false
          if (error && error.message) {
            this.$root.$bvToast.toast(error.message, {
              title: 'Atenção!',
              variant: 'warning',
              solid: true,
            })
          } else {
            this.$root.$bvToast.toast(
              'Não foi possível se conectar ao servidor.',
              {
                title: 'Erro!',
                variant: 'danger',
                solid: true,
              },
            )
          }
        }
      }
    },
  },

  validations: {
    time: {
      descricao: {
        required,
        minLength: minLength(3),
      },
      modalidade_id: {
        required,
      },
      categoria_id: {
        required,
      },
    },
  },

  mounted() {
    this.getAtletasByEscola()
    this.getTecnicosByEscola()
    this.getModalidades()
    if (this.timeId) {
      this.getTime()
    } else {
      this.$bvModal.show('modalCreateEditTime')
    }
  },
}
</script>
