<template>
  <Layout>
    <PageHeader :title="title" :items="items" />
    <CreateEditAtletaModal
      v-if="showCreateEditAtletaModal"
      :atletaId="atletaIdSelected"
      @closeModal="closeCreateEditAtletaModal"
    />
    <LoadingData v-if="loadData" />
    <div v-else class="card">
      <div
        class="card-header d-flex justify-content-between align-items-center bg-transparent border-bottom"
      >
        <h5 class="card-title mb-0">Atletas</h5>
        <div v-if="getModuleUserByCode('0.05.002')">
          <button
            type="button"
            class="btn btn-success"
            title="Relatório"
            @click="generatePDF()"
          >
            Gerar PDF
          </button>
          <!-- <button
            type="button"
            class="btn btn-primary"
            title="Novo Usuário"
            @click="createEditAtleta()"
          >
            Novo Atleta
          </button> -->
        </div>
      </div>
      <div class="card-body">
        <ListAtletas :atletas="atletas" @editAtleta="createEditAtleta" />
      </div>
      <div id="pdf-content" v-if="showPdfContent">
        <ListAtletasRelatorio :atletas="atletas" />
      </div>
    </div>
  </Layout>
</template>

<script>
import { mapGetters } from 'vuex'
import Layout from '@/views/layouts/Layout.vue'
import PageHeader from '@/components/commons/PageHeader.vue'
import LoadingData from '@/components/commons/LoadingData.vue'
import CreateEditAtletaModal from '@/components/administration/atletas/CreateEditAtletaModal.vue'
import html2pdf from 'html2pdf.js'
import ListAtletas from '../../../components/administration/atletas/ListAtletas.vue'
import ListAtletasRelatorio from '../../../components/administration/atletas/ListAtletasRelatorio.vue'

export default {
  name: 'Atletas',

  components: {
    Layout,
    PageHeader,
    CreateEditAtletaModal,
    LoadingData,
    ListAtletas,
    ListAtletasRelatorio,
  },

  data() {
    return {
      title: 'Atletas',
      items: [
        {
          text: 'Administração',
        },
        {
          text: 'Unidades',
          active: true,
        },
      ],
      loadData: true,
      showCreateEditAtletaModal: false,
      atletas: null,
      atletaIdSelected: null,
      showPdfContent: false,
    }
  },

  computed: {
    ...mapGetters(['getModuleUserByCode']),
  },

  methods: {
    generatePDF() {
      this.showPdfContent = true

      this.$nextTick(() => {
        const element = document.getElementById('pdf-content')
        html2pdf()
          .from(element)
          .toPdf()
          .get('pdf')
          .then(function generatePdfBlob(pdf) {
            const blob = pdf.output('blob')
            const url = URL.createObjectURL(blob)
            window.open(url)
          })
          .finally(() => {
            this.showPdfContent = false
          })
      })
    },
    async getAtletas() {
      try {
        this.atletas = await this.$store.dispatch('getAtletas')
        this.loadData = false
      } catch (error) {
        if (error && error.message) {
          this.$root.$bvToast.toast(error.message, {
            title: 'Atenção!',
            variant: 'warning',
            solid: true,
          })
        } else {
          this.$root.$bvToast.toast(
            'Não foi possível se conectar ao servidor.',
            {
              title: 'Erro!',
              variant: 'danger',
              solid: true,
            },
          )
        }
      }
    },

    async getAtletasByEscola() {
      try {
        this.atletas = await this.$store.dispatch('getAtletasByEscola')
        this.loadData = false
      } catch (error) {
        if (error && error.message) {
          this.$root.$bvToast.toast(error.message, {
            title: 'Atenção!',
            variant: 'warning',
            solid: true,
          })
        } else {
          this.$root.$bvToast.toast(
            'Não foi possível se conectar ao servidor.',
            {
              title: 'Erro!',
              variant: 'danger',
              solid: true,
            },
          )
        }
      }
    },

    createEditAtleta(atletaId) {
      this.atletaIdSelected = atletaId || null
      this.showCreateEditAtletaModal = true
    },

    closeCreateEditAtletaModal(loadListAtletas) {
      if (loadListAtletas) {
        if (this.getModuleUserByCode('1')) {
          this.getAtletas()
        } else if (this.getModuleUserByCode('2')) {
          this.getAtletasByEscola()
        }
      }
      this.showCreateEditAtletaModal = false
    },
  },

  mounted() {
    if (this.getModuleUserByCode('1')) {
      this.getAtletas()
    } else if (this.getModuleUserByCode('2')) {
      this.getAtletasByEscola()
    }
  },
}
</script>
