<template>
  <div>
    <div class="table-responsive mb-0">
      <b-table
        :items="times"
        :fields="table.fields"
        responsive="sm"
        :per-page="table.perPage"
        :current-page="table.currentPage"
        :sort-by.sync="table.sortBy"
        :sort-desc.sync="table.sortDesc"
        :filter="table.filter"
        :filter-included-fields="table.filterOn"
        @filtered="onFiltered"
      >
        <template #cell(foto)="row">
          <span v-html="row.value"></span>
        </template>
      </b-table>
    </div>
    <div class="row">
      <div class="col d-inline-flex align-items-center">
        Quantidade de times cadastrados: {{ times.length }}
      </div>
    </div>
  </div>
</template>

<script>
import { mapGetters } from 'vuex'
import { imageList } from '@/utils/strings'
import { dateTimeSqlToDateBR } from '@/utils/date'

export default {
  name: 'ListAtletasRelatorio',

  props: {
    times: {
      type: Array,
      required: true,
    },
  },

  data() {
    return {
      table: {
        totalRows: 1,
        currentPage: 1,
        sortBy: 'nome',
        sortDesc: false,
        fields: [
          { key: 'descricao', label: 'Descrição' },
          { key: 'escola', label: 'Escola' },
          { key: 'modalidade', label: 'Modalidade' },
          { key: 'categoria', label: 'Categoria' },
        ],
      },
    }
  },

  computed: {
    ...mapGetters(['getModuleUserByCode']),
  },

  mounted() {
    this.table.totalRows = this.times.length
  },
}
</script>
