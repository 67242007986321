import { dateTimeSqlToDateTimeBR } from './date'

const createdUpdatedUser = (userName, date) => {
  return userName !== null && userName !== ''
    ? `${userName} em ${dateTimeSqlToDateTimeBR(date)}`
    : '-'
}

const statusTranslate = (value) => {
  const status = parseInt(value, 10)
  switch (status) {
    case 1:
      return '<span class="badge badge-success">Ativo</span>'
    case 0:
      return '<span class="badge badge-danger">Inativo</span>'
    default:
      return '<span class="badge badge-secondary">Indefinido</span>'
  }
}

const imageList = (value) => {
  const baseUrl = process.env.VUE_APP_BASE_URL_API
  if (value) {
    return `<img src="/atletas/${value}" class="avatar-sm mr-3 rounded-circle" />`
  }
  return `<img src=""/>`
}

const imagePreSumulaAtletas = (value) => {
  const baseUrl = process.env.VUE_APP_BASE_URL_API
  if (value) {
    return `<img src="/atletas/${value}" class=" avatar-lg" />`
  }
  return `<img src=""/>`
}
const imagePreSumulaTecnicos = (value) => {
  const baseUrl = process.env.VUE_APP_BASE_URL_API
  if (value) {
    return `<img src="/tecnicos/${value}" class=" avatar-lg" />`
  }
  return `<img src=""/>`
}

const imageListTecnicos = (value) => {
  const baseUrl = process.env.VUE_APP_BASE_URL_API
  if (value) {
    return `<img src="/tecnicos/${value}" class="avatar-sm mr-3 rounded-circle" />`
  }
  return `<img src=""/>`
}

const imageListEscolas = (value) => {
  const baseUrl = process.env.VUE_APP_BASE_URL_API
  if (value) {
    return `<img src="/escolas/${value}" class="avatar-lg" />`
  }
  return `<img src=""/>`
}

const obrigatoriaTranslate = (value) => {
  const status = parseInt(value, 10)
  switch (status) {
    case 1:
      return '<span class="badge badge-success">Sim</span>'
    case 0:
      return '<span class="badge badge-danger">Não</span>'
    default:
      return '<span class="badge badge-secondary">Indefinido</span>'
  }
}

const tipoCategoriaConta = (value) => {
  const status = parseInt(value, 10)
  switch (status) {
    case 1:
      return '<span class="badge badge-success">Receita</span>'
    case 0:
      return '<span class="badge badge-danger">Despesa</span>'
    default:
      return '<span class="badge badge-secondary">Indefinido</span>'
  }
}

const statusProjectTranslate = (value) => {
  const status = parseInt(value, 10)
  switch (status) {
    case 0:
      return '<span class="badge badge-danger">Reprovado</span>'
    case 1:
      return '<span class="badge badge-warning">Aguardando Aprovação</span>'
    case 2:
      return '<span class="badge badge-primary">Em Andamento</span>'
    case 3:
      return '<span class="badge badge-success">Concluído</span>'
    default:
      return '<span class="badge badge-secondary">Indefinido</span>'
  }
}

const statusContratoTranslate = (value) => {
  const status = parseInt(value, 10)
  switch (status) {
    case 0:
      return '<span class="badge badge-warning">Enviado para assinatura</span>'
    case 1:
      return '<span class="badge badge-success">Aprovado</span>'
    case 2:
      return '<span class="badge badge-primary">Vencido</span>'
    case 3:
      return '<span class="badge badge-success">Renovado</span>'
    case 4:
      return '<span class="badge badge-danger">Encerrado</span>'
    default:
      return '<span class="badge badge-secondary">Indefinido</span>'
  }
}

const statusPlanejamentoTranslate = (value) => {
  const status = parseInt(value, 10)
  switch (status) {
    case 1:
      return '<span class="badge badge-primary">Em criação</span>'
    case 2:
      return '<span class="badge badge-danger">Em revisão interna</span>'
    case 3:
      return '<span class="badge badge-secondary">Enviado para aprovação</span>'
    case 4:
      return '<span class="badge badge-success">Aprovado</span>'
    case 5:
      return '<span class="badge badge-info">Em execução</span>'
    case 6:
      return '<span class="badge badge-success">Concluído</span>'
    default:
      return '<span class="badge badge-secondary">Sem status</span>'
  }
}

const tipoLancamentoTranslate = (value) => {
  const tipo = parseInt(value, 10)
  switch (tipo) {
    case 1:
      return '<span class="badge badge-success">A Receber</span>'
    case 2:
      return '<span class="badge badge-danger">A Pagar</span>'
    case 3:
      return '<span class="badge badge-success">Entrada</span>'
    case 4:
      return '<span class="badge badge-danger">Saída</span>'
    default:
      return '<span class="badge badge-secondary">Indefinido</span>'
  }
}

const origemLancamentoTranslate = (value) => {
  const tipo = parseInt(value, 10)
  switch (tipo) {
    case 1:
      return '<label>Cliente</label>'
    case 2:
      return '<label>Fornecedor</label>'
    case 3:
      return '<label>Colaborador</label>'
    default:
      return '<label>Indefinido</label>'
  }
}

const moneyToCurrencyBr = (value) => {
  if (value !== null && value !== '') {
    const money = parseFloat(value)
    return money.toLocaleString('pt-br', { style: 'currency', currency: 'BRL' })
  }
  return value
}

const valorFormatado = (item) => {
  const tipo = parseInt(item.tipo, 10)
  switch (tipo) {
    case 1:
      return `<h6 class="d-none d-sm-inline-block text-success"> ${moneyToCurrencyBr(
        item.valor_total,
      )}</h6>`
    case 2:
      return `<h6 class="d-none d-sm-inline-block text-danger">${moneyToCurrencyBr(
        item.valor_total,
      )}</h6>`
    case 3:
      return `<h6 class="d-none d-sm-inline-block text-success">${moneyToCurrencyBr(
        item.valor_total,
      )}</h6>`
    case 4:
      return `<h6 class="d-none d-sm-inline-block text-danger">${moneyToCurrencyBr(
        item.valor_total,
      )}</h6>`
    default:
      return `<h6 class="d-none d-sm-inline-block text-secondary">${tipo}- ${moneyToCurrencyBr(
        item.valor_total,
      )}</h6>`
  }
}

export {
  createdUpdatedUser,
  statusTranslate,
  statusProjectTranslate,
  moneyToCurrencyBr,
  statusContratoTranslate,
  statusPlanejamentoTranslate,
  tipoCategoriaConta,
  valorFormatado,
  tipoLancamentoTranslate,
  origemLancamentoTranslate,
  obrigatoriaTranslate,
  imageList,
  imageListTecnicos,
  imageListEscolas,
  imagePreSumulaAtletas,
  imagePreSumulaTecnicos,
}
