<template>
  <div>
    <div class="table-responsive mb-0">
      <b-table
        :items="atletas"
        :fields="table.fields"
        responsive="sm"
        :per-page="table.perPage"
        :current-page="table.currentPage"
        :sort-by.sync="table.sortBy"
        :sort-desc.sync="table.sortDesc"
        :filter="table.filter"
        :filter-included-fields="table.filterOn"
        @filtered="onFiltered"
      >
        <template #cell(foto)="row">
          <span v-html="row.value"></span>
        </template>
      </b-table>
    </div>
    <div class="row">
      <div class="col d-inline-flex align-items-center">
        Quantidade de atletas cadastrados: {{ atletas.length }}
      </div>
    </div>
  </div>
</template>

<script>
import { mapGetters } from 'vuex'
import { imageList } from '@/utils/strings'
import { dateTimeSqlToDateBR } from '@/utils/date'

export default {
  name: 'ListAtletasRelatorio',

  props: {
    atletas: {
      type: Array,
      required: true,
    },
  },

  data() {
    return {
      table: {
        totalRows: 1,
        currentPage: 1,
        sortBy: 'nome',
        sortDesc: false,
        fields: [
          {
            key: 'foto',
            label: '',
            sortByFormatted: true,
            filterByFormatted: true,
            formatter: (value) => {
              return imageList(value)
            },
            thStyle: { width: '50px' },
          },
          { key: 'nome', label: 'Nome' },
          {
            key: 'data_nascimento',
            label: 'Data de Nasc.',
            sortByFormatted: true,
            filterByFormatted: true,
            formatter: (value) => {
              return dateTimeSqlToDateBR(value)
            },
          },
          { key: 'cpf', label: 'CPF' },
          { key: 'escola', label: 'Escola' },
        ],
      },
    }
  },

  computed: {
    ...mapGetters(['getModuleUserByCode']),

    imageUrl() {
      const baseUrl = process.env.VUE_APP_BASE_URL_API
      return `${baseUrl}/atleta-image/12.png`
    },
  },

  mounted() {
    this.table.totalRows = this.atletas.length
  },
}
</script>
