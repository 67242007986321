<template>
  <Layout>
    <PageHeader :title="title" :items="items" />
    <CreateEditModalidadeModal
      v-if="showCreateEditModalidadeModal"
      :modalidadeId="modalidadeIdSelected"
      @closeModal="closeCreateEditModalidadeModal"
    />
    <LoadingData v-if="loadData" />
    <div v-else class="card">
      <div
        class="card-header d-flex justify-content-between align-items-center bg-transparent border-bottom"
      >
        <h5 class="card-title mb-0">Modalidades</h5>
        <div v-if="getModuleUserByCode('0.03.002')">
          <button
            type="button"
            class="btn btn-primary"
            title="Novo Usuário"
            @click="createEditModalidade()"
          >
            Nova Modalidade
          </button>
        </div>
      </div>
      <div class="card-body">
        <ListModalidades
          :modalidades="modalidades"
          @editModalidade="createEditModalidade"
        />
      </div>
    </div>
  </Layout>
</template>

<script>
import { mapGetters } from 'vuex'
import Layout from '@/views/layouts/Layout.vue'
import PageHeader from '@/components/commons/PageHeader.vue'
import LoadingData from '@/components/commons/LoadingData.vue'
import CreateEditModalidadeModal from '@/components/administration/modalidades/CreateEditModalidadeModal.vue'
import ListModalidades from '../../../components/administration/modalidades/ListModalidades.vue'

export default {
  name: 'Modalidades',

  components: {
    Layout,
    PageHeader,
    CreateEditModalidadeModal,
    LoadingData,
    ListModalidades,
  },

  data() {
    return {
      title: 'modalidades',
      items: [
        {
          text: 'Administração',
        },
        {
          text: 'Modalidades',
          active: true,
        },
      ],
      loadData: true,
      showCreateEditModalidadeModal: false,
      modalidades: null,
      modalidadeIdSelected: null,
    }
  },

  computed: {
    ...mapGetters(['getModuleUserByCode']),
  },

  methods: {
    async getModalidades() {
      try {
        this.modalidades = await this.$store.dispatch('getModalidades')
        this.loadData = false
      } catch (error) {
        if (error && error.message) {
          this.$root.$bvToast.toast(error.message, {
            title: 'Atenção!',
            variant: 'warning',
            solid: true,
          })
        } else {
          this.$root.$bvToast.toast(
            'Não foi possível se conectar ao servidor.',
            {
              title: 'Erro!',
              variant: 'danger',
              solid: true,
            },
          )
        }
      }
    },

    createEditModalidade(modalidadeId) {
      this.modalidadeIdSelected = modalidadeId || null
      this.showCreateEditModalidadeModal = true
    },

    closeCreateEditModalidadeModal(loadListModalidades) {
      if (loadListModalidades) {
        this.getModalidades()
      }
      this.showCreateEditModalidadeModal = false
    },
  },

  mounted() {
    this.getModalidades()
  },
}
</script>
