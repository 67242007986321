<template>
  <Layout>
    <PageHeader :title="title" :items="items" />
    <EscolaDados :escolaId="escolaId" />
  </Layout>
</template>

<script>
import Layout from '@/views/layouts/Layout.vue'
import PageHeader from '@/components/commons/PageHeader.vue'
import EscolaDados from '@/components/administration/escolas/EscolaDados.vue'

export default {
  name: 'DadosEscola',

  components: {
    Layout,
    PageHeader,
    EscolaDados,
  },

  data() {
    return {
      escolaId: this.$store.getters.user.id,
      title: 'Dados da Escola',
      items: [
        {
          text: 'Administração',
        },
        {
          text: 'Dados da Escola',
          active: true,
        },
      ],
    }
  },
}
</script>
