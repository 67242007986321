<template>
  <b-modal
    id="modalCreateEditEscola"
    size="xl"
    :title="textModal"
    title-class="font-18"
    hide-footer
    no-close-on-backdrop
    @hidden="$emit('closeModal', false)"
  >
    <form @submit.prevent="save">
      <!-- NOME E DATA DENASCIMENTO -->
      <div class="form-row">
        <div class="form-group col-sm-12">
          <label>Nome: <span class="text-danger">*</span></label>
          <input
            type="text"
            class="form-control"
            :class="{ 'is-invalid': $v.escola.descricao.$error }"
            placeholder="Nome da escola"
            v-model="$v.escola.descricao.$model"
          />
          <span v-if="!$v.escola.descricao.required" class="invalid-feedback">
            O campo é obrigatório
          </span>
        </div>
      </div>
      <!-- CPF E RESPONSAVEL -->
      <div class="form-row">
        <div class="form-group col-sm-6">
          <label>CNPJ: <span class="text-danger">*</span></label>
          <the-mask
            type="text"
            class="form-control"
            :class="{ 'is-invalid': $v.escola.cnpj.$error }"
            placeholder="00.000.000/0000-00"
            v-model="$v.escola.cnpj.$model"
            :mask="'##.###.###/####-##'"
            :masked="true"
          />
          <span v-if="!$v.escola.cnpj.required" class="invalid-feedback">
            O campo é obrigatório
          </span>
        </div>
        <div class="form-group col-sm-6">
          <label>Responsável:</label>
          <input
            type="text"
            class="form-control"
            :class="{ 'is-invalid': $v.escola.responsavel.$error }"
            placeholder="Nome do responsável"
            v-model="$v.escola.responsavel.$model"
          />
        </div>
      </div>

      <!-- EMAIL E CELULAR -->
      <div class="form-row">
        <div class="form-group col-sm-6">
          <label>E-mail: <span class="text-danger">*</span></label>
          <input
            type="email"
            class="form-control"
            :class="{ 'is-invalid': $v.escola.email.$error }"
            v-model="$v.escola.email.$model"
            placeholder="usuario@email.com.br"
          />
          <span v-if="!$v.escola.email.required" class="invalid-feedback">
            O campo é obrigatório
          </span>
          <span v-if="!$v.escola.email.email" class="invalid-feedback">
            Formato de e-mail inválido
          </span>
        </div>
        <div class="form-group col-sm-6">
          <label>Telefone:</label>
          <the-mask
            type="text"
            class="form-control"
            :class="{ 'is-invalid': $v.escola.telefone.$error }"
            placeholder="(00) 98888-8888"
            v-model="$v.escola.telefone.$model"
            :mask="['(##) ####-####', '(##) #####-####']"
            :masked="true"
          />
          <span v-if="!$v.escola.telefone.minLength" class="invalid-feedback">
            Formato de telefone inválido <br />
            Ex: (00) 98888-8888 / (00) 8888-8888
          </span>
        </div>
      </div>

      <!-- BAIRRO, ESTADO E CIDADE -->
      <div class="form-row">
        <div class="form-group col-sm-6">
          <label>Estado: <span class="text-danger">*</span></label>
          <select
            class="form-control"
            :class="{ 'is-invalid': $v.escola.city_state_id.$error }"
            v-model="$v.escola.city_state_id.$model"
          >
            <option value="">Selecione o estado</option>
            <option
              v-for="state in states"
              :key="state.id"
              :value="state.id"
              :title="`${state.uf} - ${state.name}`"
            >
              {{ state.uf }}
            </option>
          </select>
          <span
            v-if="!$v.escola.city_state_id.required"
            class="invalid-feedback"
          >
            O campo é obrigatório
          </span>
        </div>
        <div class="form-group col-sm-6">
          <label>Cidade: <span class="text-danger">*</span></label>
          <select
            class="form-control"
            :class="{ 'is-invalid': $v.escola.city_id.$error }"
            v-model="$v.escola.city_id.$model"
          >
            <option value="">Selecione uma cidade</option>
            <option
              v-for="city in cities"
              :key="city.id"
              :value="city.id"
              :title="city.name"
            >
              {{ city.name }}
            </option>
          </select>
          <span v-if="!$v.escola.city_id.required" class="invalid-feedback">
            O campo é obrigatório
          </span>
        </div>
      </div>

      <div v-if="escolaId" class="form-row">
        <div class="form-group col-sm-6">
          <p>Status: <span class="text-danger">*</span></p>
          <div class="form-check form-check-inline">
            <input
              type="radio"
              class="form-check-input"
              id="statusInativo"
              value="0"
              v-model="escola.status"
            />
            <label class="form-check-label" for="statusInativo">
              Inativo
            </label>
          </div>
          <div class="form-check form-check-inline">
            <input
              type="radio"
              class="form-check-input"
              id="statusAtivo"
              value="1"
              v-model="escola.status"
            />
            <label class="form-check-label" for="statusAtivo"> Ativo </label>
          </div>
        </div>
      </div>
      <hr />
      <div class="modal-footer-custom">
        <button
          type="button"
          class="btn btn-secondary"
          title="Cancelar"
          @click="$emit('closeModal', false)"
        >
          Cancelar
        </button>
        <button
          type="submit"
          class="btn btn-primary"
          :title="textModal"
          :disabled="$v.$invalid || disableButton"
        >
          <i
            v-if="disableButton"
            class="spinner-grow spinner-grow-custom text-wither"
          />
          <span v-else>{{ textModal }}</span>
        </button>
      </div>
    </form>
  </b-modal>
</template>

<script>
import { required, minLength, maxLength, email } from 'vuelidate/lib/validators'
import { TheMask } from 'vue-the-mask'

export default {
  name: 'CreateEditEscolaModal',

  components: {
    TheMask,
  },

  props: {
    escolaId: {
      type: String,
      default: null,
    },
  },

  data() {
    return {
      escola: {
        descricao: '',
        cnpj: '',
        email: '',
        telefone: '',
        responsavel: '',
        city_id: '',
        city_state_id: '',
        perfil_id: 2,
      },
      loadDataEditing: false,
      disableButton: false,
      states: null,
      cities: null,
    }
  },

  computed: {
    textModal() {
      return this.escolaId ? 'Atualizar' : 'Cadastrar'
    },
  },

  watch: {
    'escola.city_state_id': {
      handler(newValue, oldValue) {
        if (!this.loadDataEditing) {
          this.escola.city_id = ''
        }
        if (newValue !== '') {
          this.getCities()
        }
      },
    },
  },

  methods: {
    async getEscola() {
      try {
        this.loadDataEditing = true
        this.escola = await this.$store.dispatch('getEscola', this.escolaId)
        this.$bvModal.show('modalCreateEditEscola')
      } catch (error) {
        if (error && error.message) {
          this.$root.$bvToast.toast(error.message, {
            title: 'Atenção!',
            variant: 'warning',
            solid: true,
          })
        } else {
          this.$root.$bvToast.toast(
            'Não foi possível se conectar ao servidor.',
            {
              title: 'Erro!',
              variant: 'danger',
              solid: true,
            },
          )
        }
        this.$emit('closeModal', false)
      }
    },

    async getStates() {
      try {
        this.states = await this.$store.dispatch('getStates')
      } catch (error) {
        if (error && error.message) {
          this.$root.$bvToast.toast(error.message, {
            title: 'Atenção!',
            variant: 'warning',
            solid: true,
          })
        } else {
          this.$root.$bvToast.toast(
            'Não foi possível se conectar ao servidor.',
            {
              title: 'Erro!',
              variant: 'danger',
              solid: true,
            },
          )
        }
      }
    },

    async getCities() {
      try {
        this.cities = await this.$store.dispatch(
          'getCities',
          this.escola.city_state_id,
        )
        this.loadDataEditing = false
      } catch (error) {
        if (error && error.message) {
          this.$root.$bvToast.toast(error.message, {
            title: 'Atenção!',
            variant: 'warning',
            solid: true,
          })
        } else {
          this.$root.$bvToast.toast(
            'Não foi possível se conectar ao servidor.',
            {
              title: 'Erro!',
              variant: 'danger',
              solid: true,
            },
          )
        }
      }
    },

    async save() {
      if (!this.$v.$invalid) {
        this.disableButton = true
        try {
          let response = null
          if (this.escolaId) {
            response = await this.$store.dispatch('updateEscola', this.escola)
          } else {
            response = await this.$store.dispatch('saveEscola', this.escola)
          }
          this.disableButton = false
          this.$root.$bvToast.toast(response.message, {
            title: 'Sucesso!',
            variant: 'success',
            solid: true,
          })
          this.$emit('closeModal', true)
        } catch (error) {
          this.disableButton = false
          if (error && error.message) {
            this.$root.$bvToast.toast(error.message, {
              title: 'Atenção!',
              variant: 'warning',
              solid: true,
            })
          } else {
            this.$root.$bvToast.toast(
              'Não foi possível se conectar ao servidor.',
              {
                title: 'Erro!',
                variant: 'danger',
                solid: true,
              },
            )
          }
        }
      }
    },
  },

  validations: {
    escola: {
      descricao: {
        required,
      },
      email: {
        required,
        email,
      },
      telefone: {
        minLength: minLength(14),
        maxLength: maxLength(15),
      },
      cnpj: {
        required,
      },
      responsavel: {
        required,
      },
      city_id: {
        required,
      },
      city_state_id: {
        required,
      },
    },
  },

  mounted() {
    this.getStates()
    if (this.escolaId) {
      this.getEscola()
    } else {
      this.$bvModal.show('modalCreateEditEscola')
    }
  },
}
</script>
