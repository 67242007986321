<template>
  <b-modal
    id="modalCreateEditEquipeDeApoio"
    size="xl"
    :title="textModal"
    title-class="font-18"
    hide-footer
    no-close-on-backdrop
    @hidden="$emit('closeModal', false)"
  >
    <form @submit.prevent="save">
      <div class="form-row">
        <div class="form-group col-sm-8">
          <label>Nome: <span class="text-danger">*</span></label>
          <input
            type="text"
            class="form-control"
            :class="{ 'is-invalid': $v.equipe.nome.$error }"
            placeholder="Nome do membro da equipe"
            v-model="$v.equipe.nome.$model"
          />
          <span v-if="!$v.equipe.nome.required" class="invalid-feedback">
            O campo é obrigatório
          </span>
        </div>
        <div class="form-group col-sm-4">
          <label>Data de nascimento: <span class="text-danger">*</span></label>
          <input
            type="date"
            class="form-control"
            :class="{ 'is-invalid': $v.equipe.data_nascimento.$error }"
            placeholder="Data de nascimento"
            v-model="$v.equipe.data_nascimento.$model"
          />
          <span
            v-if="!$v.equipe.data_nascimento.required"
            class="invalid-feedback"
          >
            O campo é obrigatório
          </span>
        </div>
      </div>
      <!-- CPF E RG -->
      <div class="form-row">
        <div class="form-group col-sm-6">
          <label>CPF: <span class="text-danger">*</span></label>
          <the-mask
            type="text"
            class="form-control"
            :class="{ 'is-invalid': $v.equipe.cpf.$error }"
            placeholder="000.000.000-00"
            v-model="$v.equipe.cpf.$model"
            :mask="'###.###.###-##'"
            :masked="true"
          />
          <span v-if="!$v.equipe.cpf.required" class="invalid-feedback">
            O campo é obrigatório
          </span>
        </div>
        <div class="form-group col-sm-6">
          <label>RG:</label>
          <input
            type="text"
            class="form-control"
            :class="{ 'is-invalid': $v.equipe.rg.$error }"
            placeholder="Nº do RG"
            v-model="$v.equipe.rg.$model"
          />
          <span v-if="!$v.equipe.rg.required" class="invalid-feedback">
            O campo é obrigatório
          </span>
        </div>
      </div>

      <!-- EMAIL E CELULAR -->
      <div class="form-row">
        <div class="form-group col-sm-6">
          <label>E-mail: <span class="text-danger">*</span></label>
          <input
            type="email"
            class="form-control"
            :class="{ 'is-invalid': $v.equipe.email.$error }"
            v-model="$v.equipe.email.$model"
            placeholder="usuario@email.com.br"
          />
          <span v-if="!$v.equipe.email.required" class="invalid-feedback">
            O campo é obrigatório
          </span>
          <span v-if="!$v.equipe.email.email" class="invalid-feedback">
            Formato de e-mail inválido
          </span>
        </div>
        <div class="form-group col-sm-6">
          <label>Telefone:</label>
          <the-mask
            type="text"
            class="form-control"
            :class="{ 'is-invalid': $v.equipe.telefone.$error }"
            placeholder="(00) 98888-8888"
            v-model="$v.equipe.telefone.$model"
            :mask="['(##) ####-####', '(##) #####-####']"
            :masked="true"
          />
          <span v-if="!$v.equipe.telefone.minLength" class="invalid-feedback">
            Formato de telefone inválido <br />
            Ex: (00) 98888-8888 / (00) 8888-8888
          </span>
        </div>
      </div>
      <!-- Logo -->
      <!-- <div class="form-row">
        <label>Foto:</label>
        <input
          class="form-control"
          id="image"
          type="file"
          @change="handleFileUpload"
        />
      </div> -->
      <hr />
      <div class="modal-footer-custom">
        <button
          type="button"
          class="btn btn-secondary"
          title="Cancelar"
          @click="$emit('closeModal', false)"
        >
          Cancelar
        </button>
        <button
          type="submit"
          class="btn btn-primary"
          :title="textModal"
          :disabled="$v.$invalid || disableButton"
        >
          <i
            v-if="disableButton"
            class="spinner-grow spinner-grow-custom text-wither"
          />
          <span v-else>{{ textModal }}</span>
        </button>
      </div>
    </form>
  </b-modal>
</template>

<script>
import { required, minLength, email, maxLength } from 'vuelidate/lib/validators'
import { TheMask } from 'vue-the-mask'

export default {
  name: 'CreateEditEquipeDeApoioModal',
  props: {
    equipeId: {
      type: String,
      default: null,
    },
  },

  components: {
    TheMask,
  },

  data() {
    return {
      equipe: {
        nome: '',
        data_nascimento: '',
        rg: '',
        cpf: '',
        telefone: '',
        email: '',
      },
      disableButton: false,
    }
  },

  computed: {
    textModal() {
      return this.equipeId ? 'Atualizar' : 'Cadastrar'
    },
  },

  methods: {
    async getEquipe() {
      try {
        this.equipe = await this.$store.dispatch('getEquipe', this.equipeId)
        this.$bvModal.show('modalCreateEditEquipeDeApoio')
      } catch (error) {
        if (error && error.message) {
          this.$root.$bvToast.toast(error.message, {
            title: 'Atenção!',
            variant: 'warning',
            solid: true,
          })
        } else {
          this.$root.$bvToast.toast(
            'Não foi possível se conectar ao servidor.',
            {
              title: 'Erro!',
              variant: 'danger',
              solid: true,
            },
          )
        }
        this.$emit('closeModal', false)
      }
    },

    async save() {
      if (!this.$v.$invalid) {
        this.disableButton = true
        try {
          let response = null
          if (this.equipeId) {
            response = await this.$store.dispatch('updateEquipe', this.equipe)
          } else {
            response = await this.$store.dispatch('saveEquipe', this.equipe)
          }
          this.disableButton = false
          this.$root.$bvToast.toast(response.message, {
            title: 'Sucesso!',
            variant: 'success',
            solid: true,
          })
          this.$emit('closeModal', true)
        } catch (error) {
          this.disableButton = false
          if (error && error.message) {
            this.$root.$bvToast.toast(error.message, {
              title: 'Atenção!',
              variant: 'warning',
              solid: true,
            })
          } else {
            this.$root.$bvToast.toast(
              'Não foi possível se conectar ao servidor.',
              {
                title: 'Erro!',
                variant: 'danger',
                solid: true,
              },
            )
          }
        }
      }
    },
  },

  validations: {
    equipe: {
      nome: {
        required,
      },
      email: {
        required,
        email,
      },
      telefone: {
        minLength: minLength(14),
        maxLength: maxLength(15),
      },
      cpf: {
        required,
      },
      data_nascimento: {
        required,
      },
      rg: {
        required,
      },
    },
  },

  mounted() {
    if (this.equipeId) {
      this.getEquipe()
    } else {
      this.$bvModal.show('modalCreateEditEquipeDeApoio')
    }
  },
}
</script>
