<template>
  <Layout>
    <PageHeader :title="title" :items="items" />
    <CreateEditEquipeDeApoioModal
      v-if="showCreateEditEquipeDeApoioModal"
      :equipeId="equipeIdSelected"
      @closeModal="closeCreateEditEquipeDeApoioModal"
    />
    <LoadingData v-if="loadData" />
    <div v-else class="card">
      <div
        class="card-header d-flex justify-content-between align-items-center bg-transparent border-bottom"
      >
        <h5 class="card-title mb-0">Equipe de Apoio</h5>
        <div v-if="getModuleUserByCode('0.09.002')">
          <!-- <button
            type="button"
            class="btn btn-primary"
            title="Novo Usuário"
            @click="createEditEquipeDeApoio()"
          >
            Novo Membro da equipe
          </button> -->
        </div>
      </div>
      <div v-if="getModuleUserByCode('0.09.002')" class="card-body">
        <ListEquipesDeApoio
          :equipes="equipes"
          @editEquipe="createEditEquipeDeApoio"
        />
      </div>
    </div>
  </Layout>
</template>

<script>
import { mapGetters } from 'vuex'
import Layout from '@/views/layouts/Layout.vue'
import PageHeader from '@/components/commons/PageHeader.vue'
import LoadingData from '@/components/commons/LoadingData.vue'
import CreateEditEquipeDeApoioModal from '@/components/administration/equipedeapoio/CreateEditEquipeDeApoioModal.vue'
import ListEquipesDeApoio from '../../../components/administration/equipedeapoio/ListEquipesDeApoio.vue'

export default {
  name: 'EquipeDeApoio',

  components: {
    Layout,
    PageHeader,
    CreateEditEquipeDeApoioModal,
    LoadingData,
    ListEquipesDeApoio,
  },

  data() {
    return {
      title: 'Equipe de Apoio',
      items: [
        {
          text: 'Administração',
        },
        {
          text: 'Equipe de Apoio',
          active: true,
        },
      ],
      loadData: true,
      showCreateEditEquipeDeApoioModal: false,
      equipes: null,
      equipeIdSelected: null,
    }
  },

  computed: {
    ...mapGetters(['getModuleUserByCode']),
  },

  methods: {
    async getEquipes() {
      try {
        this.equipes = await this.$store.dispatch('getEquipes')
        this.loadData = false
      } catch (error) {
        if (error && error.message) {
          this.$root.$bvToast.toast(error.message, {
            title: 'Atenção!',
            variant: 'warning',
            solid: true,
          })
        } else {
          this.$root.$bvToast.toast(
            'Não foi possível se conectar ao servidor.',
            {
              title: 'Erro!',
              variant: 'danger',
              solid: true,
            },
          )
        }
      }
    },

    async getEquipeDeApoioByEscola() {
      try {
        this.equipes = await this.$store.dispatch('getEquipeDeApoioByEscola')
        this.loadData = false
      } catch (error) {
        if (error && error.message) {
          this.$root.$bvToast.toast(error.message, {
            title: 'Atenção!',
            variant: 'warning',
            solid: true,
          })
        } else {
          this.$root.$bvToast.toast(
            'Não foi possível se conectar ao servidor.',
            {
              title: 'Erro!',
              variant: 'danger',
              solid: true,
            },
          )
        }
      }
    },

    createEditEquipeDeApoio(equipeId) {
      this.equipeIdSelected = equipeId || null
      this.showCreateEditEquipeDeApoioModal = true
    },

    closeCreateEditEquipeDeApoioModal(loadListEquipes) {
      if (loadListEquipes) {
        if (this.getModuleUserByCode('1')) {
          this.getEquipes()
        } else if (this.getModuleUserByCode('2')) {
          this.getEquipeDeApoioByEscola()
        }
      }
      this.showCreateEditEquipeDeApoioModal = false
    },
  },

  mounted() {
    if (this.getModuleUserByCode('1')) {
      this.getEquipes()
    } else if (this.getModuleUserByCode('2')) {
      this.getEquipeDeApoioByEscola()
    }
  },
}
</script>
