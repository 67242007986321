<template>
  <b-modal
    id="modalPreSumula"
    size="xl"
    title="Pré Súmula"
    title-class="font-18"
    hide-footer
    no-close-on-backdrop
    @hidden="$emit('closeModal', false)"
  >
    <div
      class="card-header d-flex justify-content-between align-items-center bg-transparent border-bottom"
    >
      <h5 class="card-title mb-0"></h5>
      <div>
        <button
          type="button"
          class="btn btn-success"
          title="Relatório"
          @click="generatePDF()"
        >
          Gerar PDF
        </button>
      </div>
    </div>
    <div id="pdf-content" ref="pdfContent">
      <div class="card-header align-items-center bg-transparent border-bottom">
        <h6 class="card-title mb-0 text-center">Relação de atletas</h6>
        <div class="row">
          <div class="col-sm-2">
            <img :src="getSchoolImagePath(escola.logo)" class="avatar-lg" />
          </div>
          <div class="col-sm-7">
            <p><strong>Escola: </strong>{{ escola.descricao }}</p>
            <p>
              <strong>Time: </strong> {{ time.descricao }} -
              {{ time.modalidade }} -
              {{ time.categoria }}
            </p>
          </div>
          <div class="col-sm-3">
            <img
              src="@/assets/images/logo/logo-jogos-mazzarello.png"
              alt="Logo jogos mazzarello"
              class="avatar-lg"
            />
          </div>
        </div>
      </div>
      <div class="card-header align-items-center bg-transparent border-bottom">
        <h6 class="card-title mb-0 text-center">Atletas</h6>
      </div>
      <br />
      <div class="row">
        <div v-for="atleta in atletas" :key="atleta.id" class="col-lg-4">
          <b-card no-body>
            <b-row no-gutters class="align-items-center">
              <b-col md="4">
                <img :src="getBaseUrlAtleta(atleta.foto)" class="avatar-lg" />
              </b-col>
              <b-col md="8">
                <b-card-body :title="atleta.nome">
                  <b-card-text>{{
                    dateTimeSqlToDateBR(atleta.data_nascimento)
                  }}</b-card-text>
                  <b-card-text>{{ atleta.cpf }}</b-card-text>
                </b-card-body>
              </b-col>
            </b-row>
          </b-card>
        </div>
      </div>
      <hr />
      <div class="card-header align-items-center bg-transparent border-bottom">
        <h6 class="card-title mb-0 text-center">Staff</h6>
      </div>
      <div class="row">
        <div v-for="tecnico in tecnicos" :key="tecnico.id" class="col-lg-4">
          <b-card no-body>
            <b-row no-gutters class="align-items-center">
              <b-col md="4">
                <img :src="getBaseUrlTecnico(tecnico.foto)" class="avatar-lg" />
              </b-col>
              <b-col md="8">
                <b-card-body :title="tecnico.nome">
                  <b-card-text>{{
                    dateTimeSqlToDateBR(tecnico.data_nascimento)
                  }}</b-card-text>
                  <b-card-text>{{ tecnico.cpf }}</b-card-text>
                </b-card-body>
              </b-col>
            </b-row>
          </b-card>
        </div>
      </div>
      <hr />
      <div
        class="card-footer align-items-center bg-transparent border-bottom d-flex justify-content-between"
      >
        <div class="row">
          <p>
            <strong
              >Desenvolvido por Agência de Marketing Go I Assessoria Digital e
              Desenvolvimento</strong
            >
          </p>
          <span class="logo">
            <img
              src="@/assets/images/logo/logo-dark.png"
              height="60"
              alt="logo"
            />
          </span>
        </div>
      </div>
    </div>
  </b-modal>
</template>

<script>
import {
  imageList,
  imageListTecnicos,
  imagePreSumulaAtletas,
  imagePreSumulaTecnicos,
  imageListEscolas,
} from '@/utils/strings'
import { dateTimeSqlToDateBR } from '@/utils/date'
import html2pdf from 'html2pdf.js'
import html2canvas from 'html2canvas'
import jsPDF from 'jspdf'

export default {
  name: 'PreSumula',

  props: {
    timeId: {
      type: String,
      default: null,
    },
  },

  data() {
    return {
      escola: {
        descricao: '',
        cnpj: '',
        email: '',
        telefone: '',
        responsavel: '',
        city_id: '',
        city_state_id: '',
        perfil_id: '',
        logo: '',
      },
      time: {
        descricao: '',
        modalidade_id: '',
        categoria_id: '',
        escola_id: '',
        categoria: '',
        modalidade: '',
      },
      atletas: null,
      tecnicos: null,
      imageList,
      imageListTecnicos,
      dateTimeSqlToDateBR,
      imagePreSumulaAtletas,
      imagePreSumulaTecnicos,
      imageListEscolas,
    }
  },

  methods: {
    getSchoolImagePath(logo) {
      return `/escolas/${logo}`
    },
    getBaseUrlAtleta(logo) {
      return `/atletas/${logo}`
    },
    getBaseUrlTecnico(logo) {
      return `/tecnicos/${logo}`
    },

    generatePDF() {
      const element = this.$refs.pdfContent // Refere-se ao elemento HTML que você deseja capturar

      html2canvas(element, { scale: 2 }).then((canvas) => {
        const imgData = canvas.toDataURL('image/png')
        // eslint-disable-next-line new-cap
        const pdf = new jsPDF('p', 'mm', 'a4')

        // Margens
        const marginLeft = 10 // Margem esquerda em mm
        const marginTop = 10 // Margem superior em mm

        // Calcular as dimensões da imagem para caber no PDF com margens
        const imgWidth = 210 - 2 * marginLeft // Largura A4 menos as margens
        const pageHeight = 297 - 2 * marginTop // Altura A4 menos as margens
        const imgHeight = (canvas.height * imgWidth) / canvas.width
        let heightLeft = imgHeight

        let position = marginTop // Começa na margem superior

        // Adicionar imagem ao PDF
        pdf.addImage(imgData, 'PNG', marginLeft, position, imgWidth, imgHeight)
        heightLeft -= pageHeight

        // Adicionar novas páginas, se necessário
        while (heightLeft >= 0) {
          position = heightLeft - imgHeight + marginTop
          pdf.addPage()
          pdf.addImage(
            imgData,
            'PNG',
            marginLeft,
            position,
            imgWidth,
            imgHeight,
          )
          heightLeft -= pageHeight
        }

        const nomePdf = `${this.escola.descricao} - ${this.time.modalidade} ${this.time.categoria}`

        // Gerar o Blob e abrir em uma nova guia
        const pdfBlob = pdf.output('blob')
        const pdfURL = URL.createObjectURL(pdfBlob)
        window.open(pdfURL, '_blank') // Abre o PDF em uma nova guia
      })
    },
    async getEscola() {
      try {
        this.loadDataEditing = true
        this.escola = await this.$store.dispatch('getEscola', this.escolaId)
        this.$bvModal.show('modalCreateEditEscola')
      } catch (error) {
        if (error && error.message) {
          this.$root.$bvToast.toast(error.message, {
            title: 'Atenção!',
            variant: 'warning',
            solid: true,
          })
        } else {
          this.$root.$bvToast.toast(
            'Não foi possível se conectar ao servidor.',
            {
              title: 'Erro!',
              variant: 'danger',
              solid: true,
            },
          )
        }
      }
    },

    async getTime() {
      try {
        this.loadDataEditing = true
        this.time = await this.$store.dispatch('getTime', this.timeId)
        this.tecnicos = await this.$store.dispatch(
          'getTecnicosByTime',
          this.timeId,
        )
        this.atletas = await this.$store.dispatch(
          'getAtletasByTime',
          this.timeId,
        )
        this.escola = await this.$store.dispatch(
          'getEscola',
          this.time.escola_id,
        )
        this.$bvModal.show('modalPreSumula')
      } catch (error) {
        if (error && error.message) {
          this.$root.$bvToast.toast(error.message, {
            title: 'Atenção!',
            variant: 'warning',
            solid: true,
          })
        } else {
          this.$root.$bvToast.toast(
            'Não foi possível se conectar ao servidor.',
            {
              title: 'Erro!',
              variant: 'danger',
              solid: true,
            },
          )
        }
        this.$emit('closeModal', false)
      }
    },
  },

  mounted() {
    if (this.timeId) {
      this.getTime()
    }
  },
}
</script>
